import $ from 'jquery';

const $document = $(document);

function transmuteTdToTh(header, td) {
    // Pre-select current element and create new table header cell (TH)
    const th = $('<th></th>');

    // Copy content from body cell to header cell
    th.html(td.html());

    // Append newly created header cell to thead's first row
    th.appendTo(header);
}

// Add bootstrap table formatting to umbraco content
function addCmsTableFormatting() {
    // Select the tables that do not have bootstrap classes
    const tablesInContent = $('.cms-page table:not(.table)');

    if (!tablesInContent.length) {
        return;
    }

    // Add bootstrap css table classes
    tablesInContent.addClass('table table-striped');

    tablesInContent.each(function () {
        // If table has no thead, move first tbody row to thead
        let table = $(this),
            tableHeader = table.find('thead'),
            tableHeaderLines = table.find('thead>tr'),

            // Get first body row and detach it from DOM
            firstBodyRow = table.find('tbody>tr:first').detach(),

            // Get all first row's cells
            firstRowElements = firstBodyRow.find('td'),
            tableParent = table.parent();

        if (!tableParent.hasClass('table-responsive')) {
            table.wrap('<div class="table-responsive"></div>');
        }


        if (!tableHeaderLines.length) {
            // If table has no thead, create one with tr inside
            if (!tableHeader.length) {
                tableHeader = $('<thead><tr></tr></thead>');
                table.append(tableHeader);
                tableHeaderLines = table.find('thead>tr');
            } else if (!tableHeaderLines.length) {
                tableHeader.append($('<tr></tr>'));
                tableHeaderLines = table.find('thead>tr');
            }

            firstRowElements.each(function () {
                transmuteTdToTh(tableHeaderLines, $(this));
            });

        }

        // add hover only on the tables that have more than two rows (including header)
        const tableRowsCount = table.find('tr').length;
        if (tableRowsCount > 2) {
            table.addClass('table-hover');
        }
    });
}

$document.on('faqLoaded', function () {
    addCmsTableFormatting.call(this);
});

$(function () {
    addCmsTableFormatting();
});