(function enableSearchMenu(d) {
    'use strict';
    function isDescendant(parent, child) {
        var node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    const search = document.getElementById('search-dropdown');
    const searchToggle = document.querySelector('#search-dropdown > a');
    const searchMenu = document.querySelector('#search-dropdown ul.dropdown-menu');

    if (!searchToggle || !searchMenu) {
        return;
    }

    document.addEventListener('click', event => {
        if (!isDescendant(search, event.target)) {
            searchMenu.classList.remove('show');
        }
    });
}(document));

// Puts tables in responsive containers
(function makeTablesResponsive(d) {

    var CMS_PAGE_SELECTOR = '.cms-page';

    var cmsPage = d.querySelector(CMS_PAGE_SELECTOR);

    if (!cmsPage || !cmsPage.length) {
        return;
    }

    var tables = page.querySelectorAll('table.table');

    if (!tables || !tables.length) {
        return;
    }

    for (var i = 0; i < tables.length; i++) {
        (function (idx) {
            var table = tables[idx];
            var classList = table.parentElement && table.parentElement.classList;
            if (!classList.contains('table-responsive')) {
                classList.add('table-responsive');
            }
        }(i));
    }

}(document));

// Toggles navbar submenus
(function toggleNavbarSubmenus(d) {
    'use strict';
    let isSubmenuExpanded = false;

    /**
     * Toggles submenu visibility 
     */
    function showMenu(element) {
        var parent = element.parentElement;
        var ul = parent.querySelector('ul');

        if (!ul) {
            return;
        }

        collapseOtherMenus(ul);

        ul.classList.toggle('show');
        // expand child menu if there is only one <li> child
        if (ul.children && ul.children.length === 1 && ul.children[0].tagName === 'LI') {
            showMenu(ul.children[0]);
        }

        isSubmenuExpanded = ul.classList.contains('show');
    }

    /**
     * Finds all dropdown menus in navbar and attaches click event listener
     */
    function attachNavigationClickEventListeners() {
        var dropdownMenus = d.querySelectorAll('nav.navbar ul>li'); //a+.dropdown-menu

        for (var i = 0; i < dropdownMenus.length; i++) {
            (function (l) {
                var togglingElement = dropdownMenus[i].querySelector('a');
                if (!togglingElement) {
                    return;
                }

                togglingElement.addEventListener('click', function callShowMenu(event) {
                    showMenu(togglingElement);
                });
            }(i))
        }

        attachOutsideMenuClickListener();
    }

    function attachOutsideMenuClickListener() {
        const navBarLinks = d.getElementById('navbar-menu');
        const navBarOpenButton = d.getElementById('expand-navbar');
        const primaryMenu = d.querySelector('nav.navbar ul.primary-menu');
        const userProfileMenu = d.getElementById('user-profile-menu');

        d.addEventListener('click', function (event) {
            if (!event.target) {
                collapseOtherMenus();
                navBarLinks.classList.remove('expand');
                isSubmenuExpanded = false;
                return;
            }

            if (navBarLinks && navBarOpenButton) {
                if (isSameElementOrChild(navBarOpenButton, event.target)) {
                    navBarLinks.classList.toggle('expand');
                    if (!navBarLinks.classList.contains('expand')) {
						userProfileMenu.classList.remove('show');
                    }
                    return;
                }

                if (navBarLinks.classList.contains('expand') && !isSameElementOrChild(navBarLinks, event.target)) {
                    navBarLinks.classList.remove('expand');
                    userProfileMenu.classList.remove('show');
                    return;
                }
            }

            if (isSubmenuExpanded && !isChildOf(primaryMenu, event.target)) {
                collapseOtherMenus();
                isSubmenuExpanded = false;
            }
        });
    }

    function collapseOtherMenus(excludedFromCollapseItem) {
        var elements = d.querySelectorAll('nav.navbar .primary-menu ul.show');
        var elementCount = elements.length;
        if (!elementCount) {
            return;
        }

        for (var i = 0; i < elementCount; i += 1) {
            (function (idx) {

                var element = elements[idx];

                if (element !== excludedFromCollapseItem) {

                    if (!isChildOf(element, excludedFromCollapseItem)) {
                        element.classList.remove('show');

                    }

                }
            }(i));

        }
    }

    function isChildOf(parent, child) {
        var threshold = 10;
        var currentTest = child != null ? child.parentNode : null;
        while (threshold > 0 && currentTest !== null) {
            if (parent === currentTest) {
                return true;
            }

            currentTest = currentTest.parentNode;
            threshold -= 1;
        }

        return false;
    }

    function isSameElementOrChild(parent, child) {
        return parent === child || isChildOf(parent, child);
    }

    d.addEventListener('DOMContentLoaded', attachNavigationClickEventListeners);
}(document));


(function (d) {
    var content = '<a>' +
        '<i class="fa fa-warning"></i>Browsing as: <strong>({{cId}}) {{cNa}}</strong>' +
        '</a>';

    var pck = 'partner_ID_override';
    var pnck = 'partner_ID_name_override';

    function gc(name) {
        var cookies = d.cookie;
        var cookieStartIndex = cookies.indexOf(name);

        if (cookieStartIndex === -1) {
            return null;
        }

        var cookieEndIndex = cookies.indexOf(';', cookieStartIndex);
        if (cookieEndIndex === -1) {
            cookieEndIndex = cookies.length;
        }

        var cookie = cookies.substr(cookieStartIndex, cookieEndIndex - cookieStartIndex);
        if (!cookie) {
            return null;
        }

        var valueStartIndex = cookie.indexOf('=') + 1;
        var cookieValue = cookie.substr(valueStartIndex);

        return cookieValue;
    }

    function init() {
        try {
            var pc = gc(pck);
            var pnc = gc(pnck);

            if (pc && pnc) {
                var list = d.querySelector('.nav.navbar-nav>li:last-child>ul');
                var el = d.createElement('li');
                var c = content.replace('{{cId}}', pc).replace('{{cNa}}', pnc);
                el.innerHTML = c;
                list.insertBefore(el, list.firstChild);
            }
        } catch (e) {
            console.log(e);
        }
    }

    d.addEventListener('DOMContentLoaded', init);

}(document));
