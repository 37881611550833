(function() {
	const agreementCheckboxes = document.getElementsByClassName('umbraco-form-agreement-checkbox');
	if (!agreementCheckboxes || !agreementCheckboxes.length || agreementCheckboxes.length === 0) {
		return;
	}

	for (let id = 0; id < agreementCheckboxes.length; id++) {
		agreementCheckboxes[id].addEventListener('click', checkbox => toggleSubmitButtonAvailability(checkbox.target));
	}
})();


function toggleSubmitButtonAvailability(checkbox) {
	const formId = checkbox.getAttribute('data-form-id');
	if (!formId) {
		return;
	}

	const submitButton = document.getElementById(`form-submit-${formId}`); //Find the button which is enabled/disabled by the checkbox
	if (!submitButton) {
		return;
	}

	if (checkbox.checked) {
		submitButton.removeAttribute('disabled');
	} else {
		submitButton.setAttribute('disabled', 'true');
	}
}
