const Flickity = require('flickity');

function initCarousels() {
    const defaultFlickityParams = {
        cellAlign: 'center',
        contain: 1,
        wrapAround: 1,
        lazyLoad: 1
    };

    const carousels = document.querySelectorAll('[flickity-carousel]');
    if (!carousels || !carousels.length) {
        return;
    }

    Array.prototype.slice.call(carousels).forEach(e => {
        e.removeAttribute('flickity-carousel');
        const isGrouped = e.hasAttribute('flickity-group-cells');
        const isDraggable = e.hasAttribute('flickity-draggable');
        const autoPlayValue = e.getAttribute('flickity-auto-play');

        const autoPlay = parseInt(autoPlayValue || 0, 10);

        const config = { ...defaultFlickityParams, isGrouped, autoPlay, isDraggable };
        new Flickity(e, config);
    });
}

document.addEventListener('DOMContentLoaded', initCarousels);
document.addEventListener('async-view-loaded', initCarousels);
