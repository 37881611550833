import { acceptCookies, privacyCookieExists, getAcceptedCookieTypes } from './cookies';

const COOKIE_MODAL = {
    class: 'cookie-modal',
    id: 'cookie-notice-modal'
};

const COOKIE_NOTICE = {
    class: 'cookie-notice',
    id: 'cookie-notice'
};

const OUTDATED_BROWSER_NOTICE = {
    name: 'OutdatedBrowser',
    guid: 'b08d3161-3956-4558-b2f1-d63f8888354d',
    id: 'outdated-browser-notice',
    class: 'outdated-browser-notice'
};

const EDIT_COOKIES_BUTTON_ID = 'manage-cookies';

function showCookieModal(cookieNotice) {
    const modal = document.getElementById(COOKIE_MODAL.id);
    if (!modal) {
        return;
    }

    setCheckboxValues(modal);
    modal.removeAttribute('hidden');
    modal.classList.add(`show-${COOKIE_MODAL.class}`);
    hideScroll();

    // hide bottom cookie notice
    hideNotice(cookieNotice.id, cookieNotice.class);
}

function handleCookieDismiss(notice, event) {
    if (!event.target) {
        return;
    }

    if (event.target.matches('button.show-modal')) {
        showCookieModal(notice);
    } else if (event.target.matches('button.accept-all')) {
        acceptCookies(OCFPrivacySettings.cookieTypeNames.map(type => ({ type, value: true })));
        hideNotice(notice.id, notice.class);
    }
}

function agentHasKeyword(keyword) {
	return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

function isSafari() {
	const isSafariAccordingToFeatures = !!window.ApplePaySetupFeature || !!window.safari;
    // User agent check is needed, because Chrome Mobile also supports Apple Pay
    const isSafariAccordingToUserAgent = agentHasKeyword('Safari') && !agentHasKeyword('Chrome') && !agentHasKeyword('CriOS');

    return isSafariAccordingToFeatures && isSafariAccordingToUserAgent;
}

function showNoticeIfUnsupportedBrowser(notice) {
    if (sessionStorage.getItem(notice.name) || (Array.prototype.includes && !isSafari())) {
        return;
    }

    showNotice(notice, updateStorage);
}

function updateStorage(notice) {
    sessionStorage.setItem(notice.name, notice.guid);

    hideNotice(notice.id, notice.class);
}

function showNotice(notice, clickFunction) {
    const element = document.getElementById(notice.id);
    if (!element) {
        return;
    }
    element.removeAttribute('hidden');
    element.classList.add(`show-${notice.class}`);
    element.querySelector('.dismiss').addEventListener('click', (e) => clickFunction(notice, e));
}

function hideNotice(noticeId, noticeClass) {
    const element = document.getElementById(noticeId);
    if (!element) {
        return;
    }
    element.setAttribute('hidden', '');
    element.classList.remove(`show-${noticeClass}`);
}


//#region Modal functionality
function setCheckboxValues(modal) {
    const checkboxes = modal.querySelectorAll('input[type=checkbox]:not(:disabled)');
    const acceptedCookieTypes = getAcceptedCookieTypes();

    if (!acceptedCookieTypes) {
        return;
    }

    Array.prototype.forEach.call(checkboxes, (checkbox) => {
        if (acceptedCookieTypes.indexOf(checkbox.id) > -1) {
            checkbox.checked = true;
            toggleIcon(modal, checkbox);
        }
    }); 
}

function attachCookieModalEvents() {
    const modal = document.getElementById(COOKIE_MODAL.id);
    if (!modal) {
        return;
    }

    const listItems = modal.querySelectorAll('li');
    const saveButton = modal.querySelector('#save-button');
    const acceptAllButton = modal.querySelector('#accept-button');
    const checkboxes = modal.querySelectorAll('input[type=checkbox]:not(:disabled)');

    // Equivalent to listItems.forEach(...)
    // IE11 does not support NodeList.forEach()
    Array.prototype.forEach.call(listItems, (element) => {
        element.addEventListener('click', () => { toggleCookieDescription(modal, element); });
    });

    Array.prototype.forEach.call(checkboxes, (checkbox) => {
        checkbox.addEventListener('click', () => { toggleIcon(modal, checkbox); });
    });

    acceptAllButton.addEventListener('click', () => {
        const allCookies = OCFPrivacySettings.cookieTypeNames.map(type => ({ type, value: true }));
        saveCookiesAndHideModal(allCookies);
    });

    saveButton.addEventListener('click', () => {
        const checkboxValues = OCFPrivacySettings.cookieTypeNames.map(type => {
            const isCookieCheckboxChecked = modal.querySelector('#' + type).checked;
            return { type, value: isCookieCheckboxChecked };
        });

        saveCookiesAndHideModal(checkboxValues);
    });
}

/** @param {{type: string, value: boolean}[]} cookieChoices */
function saveCookiesAndHideModal(cookieChoices) {
    acceptCookies(cookieChoices);
	showScroll();
	hideNotice(COOKIE_MODAL.id, COOKIE_MODAL.class);
}

function toggleCookieDescription(modalElement, tabElement) {
    const activeElement = modalElement.querySelector('li.active');
    const activeCookieType = activeElement.getAttribute('data-cookie-type');
    const activeDescription = modalElement.querySelector(`.${activeCookieType}`);

    activeDescription.setAttribute('hidden', '');
    activeElement.classList.remove('active');

    tabElement.classList.add('active');
    const selectedCookieType = tabElement.getAttribute('data-cookie-type');
    modalElement.querySelector(`.${selectedCookieType}`).removeAttribute('hidden');
}

function toggleIcon(modalElement, checkbox) {
    const icon = modalElement.querySelector(`li[data-cookie-type=${checkbox.id}] i`);

    if (checkbox.checked) {
        icon.classList.remove('fa-times');
        icon.classList.add('fa-check');
    } else {
        icon.classList.remove('fa-check');
        icon.classList.add('fa-times');
    }
}

/**
 * Do not allow to scroll and make sure background view does not change (https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/)
 */
function hideScroll() {
    const documentElement = document.documentElement;
    const scrollY = (window.pageYOffset || documentElement.scrollTop) - (documentElement.clientTop || 0);

    documentElement.style.position = 'fixed';
    documentElement.style.width = '100%';
    documentElement.style.top = `-${scrollY}px`;
    documentElement.style.overflowY = 'scroll';
}

/**
 * Return scroll and remove added styles (https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/)
 */
function showScroll() {
    const documentElement = document.documentElement;
    const scrollY = documentElement.style.top;
    documentElement.style.position = '';
    documentElement.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
}

//#endregion

function attachCookieButtonEvent() {
    const cookieEditButton = document.getElementById(EDIT_COOKIES_BUTTON_ID);
    if (!cookieEditButton) {
        return;
    }

    cookieEditButton.addEventListener('click', showCookieModal);
}

if (!privacyCookieExists()) {
    showNotice(COOKIE_NOTICE, handleCookieDismiss);

    // special if for IE 11. matches is needed for 'handleCookieDismiss' function
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector;
    }
}
showNoticeIfUnsupportedBrowser(OUTDATED_BROWSER_NOTICE);
attachCookieButtonEvent();
attachCookieModalEvents();