
function sendEvent(action, category, label, value) {
	// if "!gtag" is used, it throws: "uncaught referenceerror is not defined" when gtag is not initialized
	if (typeof gtag === 'undefined') {
		return;
	}

	gtag('event', action,
		{
			'event_category': category,
			'event_label': label,
			'value': value ? value : 0
		});
}

// Ex.: event.detail.path = '/faq/questions/1567/1653'
function sendPageVisitedEvent(event) {
	// if "!gtag" is used, it throws: "uncaught referenceerror is not defined" when gtag is not initialized
	if (typeof gtag === 'undefined' || !event || !event.detail || !event.detail.path) {
		return;
	}

	gtag('config', 'UA-11790540-1', { // for DEV purposes use: UA-11790540-3
		'page_path': event.detail.path
	});
}

function createAndSendEvent(event) {
	sendEvent(event.detail.Action, event.detail.Category, event.detail.Label, event.detail.Value);
}

document.addEventListener('analyticsEvent', createAndSendEvent);
document.addEventListener('analyticsPageview', sendPageVisitedEvent);
