window.adjustIFrame = function adjustIFrame() {
    parent.$('body').trigger('adjustIframeHeightWithoutScrolling');
};

String.prototype.format = function () {
	var content = this;
	for (var i = 0; i < arguments.length; i++) {
		var replacement = '{' + i + '}';
		content = content.replace(replacement, arguments[i]);
	}
	return content;
};

String.prototype.hashCode = function(){
	var hash = 0, i, character;
	if (this.length == 0) return hash;
	for (i = 0; i < this.length; i++) {
		character = this.charCodeAt(i);
		hash = ((hash << 5) - hash) + character;
		hash = hash & hash; // Convert to 32bit integer
	}
	return hash;
};

function updateElementAttribute(element, selector, attribute, searchValue, replaceValue) {
	$(element).find(selector).each(function() {
		var attributeValue = $(this).attr(attribute);

		if (attributeValue) {
			var newValue = attributeValue.replace(searchValue, replaceValue);
			$(this).attr(attribute, newValue);
		}
	});
}

function showSaveNotification(show) {
    if (show == true) {
        $('#save-notification').delay(10).toggle('slow', window.adjustIFrame);
        $('#save-notification').delay(5000).toggle('slow', window.adjustIFrame); //
    }
}

function showSaveNotificationPersistant(show, cssClass, text) {
    if (show == true) {
        $('#save-notification').stop(true, true).addClass(cssClass).removeAttr('style').html(text).delay(10).show('slow', window.adjustIFrame);
    }
}


function disableFormControls() {

	var controlType;
	var form = document.forms[0];

	for (var i = 0; i < form.elements.length; i++) {
		var control = form.elements[i];
		controlType = control.type;
		if (controlType == 'select-one') {
			control.readOnly = true;
			control.disabled = true;
		}

		if (controlType == 'text') {
			control.readOnly = true;

			if (control.getAttribute('data-type') == 'date')
				control.disabled = true;
		}

		if (controlType == 'button') {
			control.disabled = true;
		}

		if (controlType == 'file') {
			control.disabled = true;
		}

		if (controlType == 'checkbox') {
			control.disabled = true;
		}
		if (controlType == 'textarea') {
			control.readOnly = true;
		}
		
		if (controlType == 'span') {
			control.readOnly = true;
		}
	}

	$('div.tab-content input[type=submit]').each(function () {
	   this.disabled = true;
	});
}

function checkVoucher(url, hasDecimalPlaces) {
	var formData = $('form').serialize();
	$.ajax({
		url: url, //supply totals because model won't return items
		type: 'POST',
		cache: false,
		data: formData,
		success: function(data) {
			
			if (typeof hasDecimalPlaces === 'undefined') {
				hasDecimalPlaces = true;
			}

			var voucherValue;
			var voucherPercentage;
			var vatValue;
			if (data['VoucherValue']) {
				$('#voucher-value').attr('class', 'green');
				voucherValue = data['VoucherValue'];
				voucherPercentage = '%';
				vatValue = data['VatValue'];

			} else {
				$('#voucher-value').attr('class', 'red');
				voucherValue = 'Voucher code is invalid';
				voucherPercentage = '';
				vatValue = data['VatValue'];
				
			}

			if (data['TransactionData']) {
				$('#tr_data').val(data['TransactionData']);
			}

			$('#label-total').fadeOut(100, function() {
				if (hasDecimalPlaces) {
					$(this).text(data['Total'].toFixed(2).toLocaleString()).fadeIn(100); //TODO: fix to show localized decimal separator
				} else {
					$(this).text(data['Total']).fadeIn(100);
				}
			});

			$('#voucher-value').fadeOut(100, function() {
				$(this).text(voucherValue).fadeIn(100);
			});
			
			$('#vat-value').fadeOut(100, function() {
				$(this).text(vatValue.toFixed(2).toLocaleString()).fadeIn(100);	//TODO: fix to show localized decimal separator
			});
			
			$('#voucher-percentage').fadeOut(100, function() {
				$(this).text(voucherPercentage).fadeIn(100);
			});

			$('#voucher-discount-details').show();

			$(document.body).trigger('total-changed', [{ model: data }]);

		}
	});

}


function checkVat(url) {
	var formData = $('form').serialize();
	$.ajax({
		url: url, //supply totals because model won't return items
		type: 'POST',
		cache: false,
		data: formData,
		success: function(data) {
			if (data == false) {
				$(document.body).trigger('vatIsNotValid');
			} else {
				$(document.body).trigger('vatIsValid');
			}
		}
	});

}


function checkTrialCoupon(url, hasDecimalPlaces) {
	var formData = $('form').serialize();
	$.ajax({
		url: url, //supply totals because model won't return items
		type: 'POST',
		cache: false,
		data: formData,
		success: function(data) {
			if (typeof hasDecimalPlaces === 'undefined') {
				hasDecimalPlaces = true;
			}

			var totalVoucherValue;
			var voucherDiscountClicks;
			var voucherRemainingClicks;
			var vatValue;
			var clicksBalance;
			var total;
			
			if ((data['ClicksBalance'] && data['VoucherDiscountClicksValue'] && data['VoucherRemainingClicksValue'])
					|| (data['VoucherDiscountClicksValue'] + data['VoucherRemainingClicksValue']) > 0) {
				$('#voucher-value').removeClass('red invalid-voucher');
				clicksBalance = data['ClicksBalance'];
				total = data['Total'];
				voucherDiscountClicks = data['VoucherDiscountClicksValue'] + ' Clicks';
				voucherRemainingClicks = data['VoucherRemainingClicksValue'] + ' Clicks';
				totalVoucherValue = (data['VoucherDiscountClicksValue'] + data['VoucherRemainingClicksValue']) + ' Clicks';
				vatValue = data['VatValue'];
				
				if (total <= clicksBalance) {
					$('.clicks-error').addClass('non-displayed-element');
					$('.clicks-after-confirm').removeClass('non-displayed-element');
					$('#confirm').removeClass('invisible-element');
				} else {
					$('.clicks-error').removeClass('non-displayed-element');
					$('.clicks-after-confirm').addClass('non-displayed-element');
					$('#confirm').addClass('invisible-element');
				}
			} else {
				$('#voucher-value').addClass('red invalid-voucher');
				voucherDiscountClicks = '';
				voucherRemainingClicks = '';
				totalVoucherValue = 'Voucher code is invalid';
				vatValue = data['VatValue'];
			}
			

			$('#label-total').fadeOut(100, function() {
				if (hasDecimalPlaces) {
					$(this).text(data['Total'].toFixed(2).toLocaleString()).fadeIn(100); //TODO: fix to show localized decimal separator
				} else {
					$(this).text(data['Total']).fadeIn(100);
				}
			});


			$('#voucher-discount-value').fadeOut(100, function() {
				$(this).text(voucherDiscountClicks).fadeIn(100);
			});
			
			$('#voucher-remaining-value').fadeOut(100, function() {
				$(this).text(voucherRemainingClicks).fadeIn(100);
			});

			$('#voucher-value').fadeOut(100, function() {
				$(this).text(totalVoucherValue).fadeIn(100);
			});

			$('#vat-value').fadeOut(100, function() {
				$(this).text(vatValue.toFixed(2).toLocaleString()).fadeIn(100);	//TODO: fix to show localized decimal separator
			});
		}
	});

}

function statesDropdown(countryDropdown, countryFieldId, stateField) {
	var hasStates = $(countryDropdown).find('option:selected').attr('data-has-state');
	var country = $(countryDropdown).find('option:selected').attr('value');
	$(countryDropdown).parent().find('#' + countryFieldId).val(country);
		if (hasStates == 'True') {
			var procemessage = '<option value="0"> Please wait...</option>';
			$(stateField + ' .states').html(procemessage).show();
			$.ajax({
				url: '/User/FieldsDropDownStates',
				data: {
					countryId: country
				},
				dataType: 'html',
				cache: false,
				type: 'GET',
				success: function (data) {
					$(stateField).removeClass('hidden');
					$(stateField + ' .states').html(data).show();
				}
			});
		} else {
			$(stateField + ' .states option').remove();
			$(stateField).addClass('hidden');
		}
}

function checkVatForCountry(countryDropdown, countryFieldId, vatField) {
	var country = $(countryDropdown).find('option:selected').attr('value');
	$(countryDropdown).parent().find('#' + countryFieldId).val(country);
	$.ajax({
		url: '/User/IsVatRequired',
		data: {
			countryId: country
		},
		dataType: 'JSON',
		cache: false,
		type: 'POST',
		success: function (result) {
			if (result.isVatNotRequired) {
				$(vatField).addClass('hidden');
			} else {
				$(vatField).removeClass('hidden');
			}
		}
	});
}

function showSubmitButtons() {
	var submitButtons = $('[name=SaveTask], [name=SubmitTask]').not('.hidden_submit_button');
	$(submitButtons).show();
}

function hideSubmitButtons() {
	var submitButtons = $('[name=SaveTask], [name=SubmitTask]').not('.hidden_submit_button');
	$(submitButtons).hide();
}

function changeAttributes(divclass, fromAttr, toAttr) {
	var urlHref = $(divclass).attr(fromAttr);
	$(divclass).attr(toAttr, urlHref);
	$(divclass).removeAttr(fromAttr);
}

export {
    changeAttributes
};
global.showSaveNotification = showSaveNotification;