(function($, comm100) {
    $(document).ready(function () {
        function initChat() {
            const chatElement = document.getElementById('comm100-button-44');
            if (!chatElement) {
                return;
            }

            comm100 = comm100 || new Object;
            comm100.chat_buttons = comm100.chat_buttons || [];
            var comm100ChatButton = new Object;
            comm100ChatButton.code_plan = 44;
            comm100ChatButton.div_id = 'comm100-button-44';
            comm100.chat_buttons.push(comm100ChatButton);
            comm100.site_id = 194542;
            comm100.main_code_plan = 44;

            var comm100Lc = document.createElement('script');
            comm100Lc.async = true;
            comm100Lc.src = 'https://chatserver.comm100.com/livechat.ashx?siteId=' + Comm100API.site_id;
            var comm100S = document.getElementsByTagName('script')[0];
            comm100S.parentNode.insertBefore(comm100Lc, comm100S);

            setTimeout(function () {
                if (!Comm100API.loaded) {
                    var lc = document.createElement('script');
                    lc.async = true;
                    lc.src = 'https://hostedmax.comm100.com/chatserver/livechat.ashx?siteId=' + Comm100API.site_id;
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(lc, s);
                }
            }, 5000);

            $('body').on('click', '#comm100-float-button-2', function () {
                var chatWindow = $('#comm100-chat-window');
                chatWindow.css({
                    'max-width': 400,
                    'max-height': '80%',
                    'top': '',
                    'bottom': 0,
                    'right': 0,
                    'left': '',
                    'width': '95%'
                });
            });
        }

        OCFPrivacySettings.executeFunctionalityScriptIfAllowed(initChat);
    });
}(jQuery, window.Comm100API = window.Comm100API || new Object));

