import $ from 'jquery';
import 'jquery-lazyload/jquery.lazyload';

const initLazyLoad = (threshhold) => {
    const images$ = $('img.lazy');
    images$.lazyload({
        threshold: threshhold || 300,
        effect: 'fadeIn'
    });

};

initLazyLoad();

document.addEventListener('async-view-loaded', () => initLazyLoad(10e6));
