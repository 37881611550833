const menus = {
	primary: {
		HTML_ID: 'menu-primary',
		TYPE: 'Primary menu (white)'
	},
	secondary: {
		HTML_ID: 'menu-secondary',
		TYPE: 'Secondary menu (green)'
	}
};

handleNavigationMenuClick({
	menuElementId: menus.primary.HTML_ID,
	menuType: menus.primary.TYPE
});

handleNavigationMenuClick({
	menuElementId: menus.secondary.HTML_ID,
	menuType: menus.secondary.TYPE
});

function handleNavigationMenuClick({ menuElementId, menuType }) {
	const menu = document.getElementById(menuElementId);
	const links = menu.querySelectorAll('a');
	Array
		.from(links)
		.filter(({ href }) => !!href)
		.forEach(link => link.addEventListener('click', () => logNavigationInGoogleAnalytics({ menuType })));
}

function logNavigationInGoogleAnalytics({ menuType }) {
	const eventDetails = {
		'detail': {
			'Category': 'Menu navigation',
			'Label': menuType,
			'Action': 'Clicked link'
		}
	};
	const event = new CustomEvent('analyticsEvent', eventDetails);
	document.dispatchEvent(event);
}