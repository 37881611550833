import $ from 'jquery';

const $body = $('body'),
    $window = $('window');

let resizeTimeout;

function getIFrame() {
    return $('iframe#legacy-content-iframe');
}

function adjustIframeHeightAndKeepPosition() {
    const scrollPosition = $body.scrollTop();

    adjustIframeHeight.call(this);

    $body.scrollTop(scrollPosition);
}

// Starts interactive tour with IntroJs
function adjustIframeHeight() {
    const $this = $(this);
    $this.height('');
    const pageHeight = $this.contents().height();
    $this.height(pageHeight);
}

$('iframe#legacy-content-iframe').on('load', function () {
    const me = this;
    const $this = $(this);
    $this.contents().on('accordionactivate', function () {
        setTimeout(adjustIframeHeightAndKeepPosition.bind(me), 50);
    });
    $this.contents().find('.collapsible-panel-header').on('click', adjustIframeHeight.bind(me));

    adjustIframeHeight.call(this);

    $window.on('resize', function () {
        if (resizeTimeout) {
            window.clearTimeout(resizeTimeout);
        }
        resizeTimeout = window.setTimeout(adjustIframeHeight.bind(me), 200);
    });
});

$body.on('afterStepChange ajax-body-appened', adjustIframeHeight.bind(getIFrame()));
$body.on('adjustIframeHeightWithoutScrolling ajax-body-appened', adjustIframeHeightAndKeepPosition.bind(getIFrame()));

